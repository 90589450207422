@import "/src/styles/mixin";

.possibilities {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: 12px;

  .check {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 5px;
    border-radius: 100%;
    background-color: var(--primary-color);
    color: var(--white-color);
  }

  .opportunity {
    @include font14;
    color: var(--secondary-color);
  }
}